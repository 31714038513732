<template>
  <!-- brands -->
  <div class="brands py-5">
    <div class="container position-relative px-4 px-sm-3">
      <div class="row row-cols-1 gy-5">
        <div class="col text-center">
          <h2 class="h5 mb-0">
            <i class="fas fa-pen-nib me-2"></i>Latest registered GBINs
          </h2>
        </div>

        <div class="col">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 gy-3">
            <template v-if="props.loading">
              <div v-for="index in 12"
                   :key="index"
                   class="col">
                <div class="card">
                  <div class="card-figure d-flex align-items-center justify-content-center">
                    <Skeletor width="115"
                              height="45" />
                  </div>

                  <div class="card-body">
                    <h3 class="card-title h6">
                      <Skeletor width="50%" />
                    </h3>
                    <p class="card-text">
                      <Skeletor width="75%" />
                    </p>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="props.brands.length > 0 && !props.loading">
              <div v-for="(brand, brandIndex) in props.brands"
                   :key="brandIndex"
                   class="col">
                <div class="card h-100">
                  <div class="card-figure d-flex align-items-center justify-content-center">
                    <img v-if="brand.logoUrl"
                         :src="brand.logoUrl"
                         :alt="brand.name"
                         class="img-fluid" />

                    <img v-else
                         src="@/assets/images/placeholder-brand.png"
                         :alt="brand.name"
                         class="img-fluid" />
                  </div>

                  <div class="card-body">
                    <h3 class="card-title h6">{{ brand.name }}</h3>
                    <router-link class="card-text stretched-link"
                                 :to="getBrandUrl(brand.name, brand.gbin)">
                      GBIN: {{ brand.gbin }}
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="col mt-4 text-center">
          <router-link to="/brands">Most recently added GBINs <i class="fal fa-arrow-right"></i></router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- /brands -->
</template>

<script setup>
import { defineProps } from "vue";

/**
 *  GETTING & LOADING (API) DATA
 */

const props = defineProps({
  loading: Boolean,
  brands: Array,
});

function getBrandUrl(name, gbin) {
  let brandName = name;

  brandName = brandName
    .normalize("NFD") // Remove accents/diacritics
    .replace(/\p{Diacritic}/gu, "") // Remove accents/diacritics
    .replace(/[^a-zA-Z0-9 \\d]/g, "") // Remove all special characters except space
    .replace(/^\s+|\s+$/g, "") // Remove whitespace at the beginning and end
    .replace(/\s+/g, "-") // Replace spaces between words with a hyphen (-)
    .toLowerCase(); // Convert into lowercase

  return `/brand/${brandName}/${gbin}`;
}
</script>

<style lang="scss"
       scoped>
      @import "@/assets/scss/_variables";

      h2 {
        color: $gbin-primary;
      }
    </style>
