import {
  msalInstance,
  tokenRequest,
} from "@/config/msalConfig.js";
import { Auth } from "@/services/auth";

export function registerGuard(router) {
  router.beforeEach(async (to) => {
    if (!Auth.initialized) {
      await Auth.initialize(router);
    }

    if (!to.meta.requiresAuth) return true;
    const request = {
      ...tokenRequest,
      redirectStartPage: to.fullPath,
    };
    const shouldProceed = await isAuthenticated(
      msalInstance,
      request
    );
    return shouldProceed ? true : "/admin-login";
  });
}

export async function isAuthenticated(instance, request) {
  const accounts = await instance.getAllAccounts();
  if (!accounts.length) {
    try {
      await instance.loginRedirect(request);
    } catch (e) {
      return false;
    }
  }
  return true;
}
