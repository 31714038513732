import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import { registerGuard } from "./guard.js";

import Home from "@/views/Home.vue";

const routes = [
  // Home dynamic page
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  // Brands main and sub dynamic pages
  {
    path: "/brands",
    name: "Brands",
    component: () => import("@/views/Brands.vue"),
    props: true,
    children: [
      {
        path: ":filter([a-z]|\\d|other)",
        name: "Brands",
        component: () => import("@/views/Brands.vue"),
      },
    ],
  },

  // Brand detail dynamic pages
  {
    path: "/brand/:name/:gbin(\\d+)",
    name: "Brand",
    component: () => import("@/views/Brand.vue"),
    props: true,
  },

  // Contact form page
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
  },

  // Get your GBIN form page
  {
    path: "/get-your-gbin",
    name: "Get your GBIN",
    component: () => import("@/views/RequestGBIN.vue"),
  },

  // All about GBIN static page
  {
    path: "/about",
    name: "All about GBIN",
    component: () => import("@/views/About.vue"),
  },

  //Admin page
  {
    //has to redirect to admin login if user is not signed in already
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/admin/Admin.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin-login",
    name: "Admin login",
    component: () => import("@/views/admin/AdminLogin.vue"),
  },

  //Brand info page
  {
    //has to redirect to admin login if user is not signed in already
    path: "/admin/brand-info",
    name: "Brand info form",
    //props: true,
    component: () => import("@/views/admin/BrandInfo.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    //has to redirect to admin login if user is not signed in already
    path: "/admin/brand-info/:id",
    name: "Brand info",
    //props: true,
    component: () => import("@/views/admin/BrandInfo.vue"),
    meta: {
      requiresAuth: true
    }
  },

  // Privacy policy static page
  // {
  //   path: "/privacy-policy",
  //   name: "Privacy policy",
  //   component: () => import("@/views/Privacy.vue"),
  // },

  // Term & Conditions static page
  // {
  //   path: "/terms-and-conditions",
  //   name: "Term & Conditions",
  //   component: () => import("@/views/Terms.vue"),
  // },

  // 404 page
  {
    path: "/:catchAll(.*)",
    name: "Page not found",
    component: () => import("@/views/PageNotFound.vue"),
  },

  // {
  //   path: '/[EXAMPLE]',
  //   name: '[EXAMPLE]]',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "[EXAMPLE]" */ '../views/[EXAMPLE].vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

trackRouter(router);

registerGuard(router)

export default router;
