<template>
  <!-- header -->
  <header class="header py-4">
    <div class="container px-4 px-sm-3">
      <div class="row mt-sm-5 justify-content-md-center">
        <div class="col-md-7 mb-1">
          <h1
            class="header__title d-flex flex-column flex-sm-row align-items-center justify-content-center mb-2 mb-sm-3"
          >
            <img
              class="header__logo img-fluid me-sm-3 mb-2 mb-sm-0"
              src="@/assets/data-port-gbin.svg"
              alt="Dataports"
            />GBIN
          </h1>

          <div class="header__slogan mb-3 text-center">
            The globally unique number to identify brands
          </div>
        </div>
      </div>

      <div class="row justify-content-sm-center mb-3">
        <div class="col-12 col-md-7 col-lg-5">
          <Search :total-count="props.totalCount" :is-large="true"></Search>
        </div>
      </div>
    </div>
  </header>
  <!-- /header -->
</template>

<script setup>
import { defineProps } from "vue";

import Search from "@/components/Search.vue";

/**
 *  GETTING & LOADING (API) DATA
 */

// Props
const props = defineProps({
  totalCount: Number,
});
</script>

<style lang="scss" scoped>
/*  ==========================================================================
    Bootstrap
    ========================================================================== */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/*  ==========================================================================
    Custom
    ========================================================================== */
@import "@/assets/scss/_variables";

/*  ==========================================================================
    Header
    ========================================================================== */
/*  Header > Title
    ========================================================================== */
.header__title {
  font-size: calc(2rem + 1.5vw);
  color: $body-color;

  @include media-breakpoint-up(sm) {
    font-size: calc(2.5rem + 1.5vw);
  }
}

/*  Header > Logo
    ========================================================================== */
.header__logo {
  width: 48px;

  @include media-breakpoint-up(sm) {
    width: 96px;
  }
}

/*  Header > Slogan
    ========================================================================== */
.header__slogan {
  font-size: 1rem;
}
</style>
