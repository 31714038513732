<template>
  <!-- spacer -->
  <div class="spacer py-5 gbin-gradient">
    <div
      class="container d-md-flex align-items-center justify-content-center px-4 px-sm-3 text-center"
    >
      <h2 class="fw-normal text-white mb-2 mb-md-0">
        {{ props.totalCount }} brands already listed
      </h2>

      <router-link
        class="btn btn-primary btn-lg rounded-pill fw-bold ms-md-3"
        to="/get-your-gbin"
      >
        Get your GBIN
      </router-link>
    </div>
  </div>
  <!-- /spacer -->
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  totalCount: Number,
});
</script>
