<template>
  <!-- brand-index -->
  <div class="brand-index py-4 bg-gray-light">
    <div class="container px-4 px-sm-3 text-center">
      <h2 class="h5"><i class="fal fa-archive me-2"></i>Brand index</h2>

      <div v-if="loading" class="brand-index__lists">
        <ul
          class="brand-index__list list-unstyled d-flex flex-wrap justify-content-center mb-0"
        >
          <li class="brand-index__list-item">
            <Skeletor width="450" />
          </li>
        </ul>

        <ul
          class="brand-index__list list-unstyled d-flex flex-wrap justify-content-center mb-0"
        >
          <li class="brand-index__list-item">
            <Skeletor width="170" />
          </li>
        </ul>

        <ul
          class="brand-index__list list-unstyled d-flex flex-wrap justify-content-center mb-0"
        >
          <li class="brand-index__list-item">
            <Skeletor width="45" />
          </li>
        </ul>
      </div>

      <div v-if="brandIndex.length > 0 && !loading" class="brand-index__lists">
        <ul
          class="brand-index__list list-unstyled d-flex flex-wrap justify-content-center mb-0"
        >
          <li
            v-for="(filter, filterIndex) in brandIndex.slice(0, 26)"
            :key="filterIndex"
            class="brand-index__list-item"
          >
            <router-link
              :title="filter"
              :to="`/brands/${filter.toLowerCase()}`"
              class="brand-index__link"
              >{{ filter }}</router-link
            >
          </li>
        </ul>

        <ul
          class="brand-index__list list-unstyled d-flex flex-wrap justify-content-center mb-0"
        >
          <li
            v-for="(filter, filterIndex) in brandIndex.slice(26, 36)"
            :key="filterIndex"
            class="brand-index__list-item"
          >
            <router-link
              :title="filter"
              :to="`/brands/${filter}`"
              class="brand-index__link"
              >{{ filter }}</router-link
            >
          </li>
        </ul>

        <ul
          class="brand-index__list list-unstyled d-flex flex-wrap justify-content-center mb-0"
        >
          <li
            v-for="(filter, filterIndex) in brandIndex.slice(36)"
            :key="filterIndex"
            class="brand-index__list-item"
          >
            <router-link
              :title="filter"
              :to="`/brands/${filter.toLowerCase()}`"
              class="brand-index__link"
              >{{ filter }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- /brand-index -->
</template>

<script setup>
import { onMounted, ref } from "vue";
import { api } from "@/utils/api";

/**
 *  GETTING & LOADING (API) DATA
 */

// App variables
const loading = ref(false);

// API results variables
const brandIndex = ref([]);

// MOUNTED
onMounted(async () => {
  getBrandIndex();
});

// Load API Brand Index data
async function getBrandIndex() {
  loading.value = true;

  try {
    const response = await api.getBrandIndex();
    brandIndex.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}
</script>

<style lang="scss" scoped>
/*  ==========================================================================
    Custom
    ========================================================================== */
@import "@/assets/scss/_variables";

/*  ==========================================================================
    Brand-index
    ========================================================================== */
h2 {
  color: $gbin-primary;
}

.brand-index {
  &__link {
    padding: 0 4.5px;
  }
}
</style>
