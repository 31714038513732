<template>
  <!-- search -->
  <div ref="root"
       class="search__wrapper">
    <div class="search__bar">
      <div class="search__input input-group"
           :class="{ 'input-group-lg': isLarge, 'input-group-sm': isCompact }">
        <input type="text"
               @click="toggleShowResults(true)"
               @keyup="handleInput"
               :value="searchQuery"
               :placeholder="props.placeholderText ?? `Find ${props.totalCount} registered brands`"
               class="form-control rounded-pill"
               :class="isCompact && 'border-0'" />
      </div>

      <button @click="toggleShowResults(true)"
              class="search__button"
              type="submit">
        <svg aria-hidden="true"
             focusable="false"
             data-prefix="fas"
             data-icon="search"
             role="img"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512 512"
             class="svg-inline--fa fa-search fa-w-16 fa-fw">
          <path fill="currentColor"
                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                class=""></path>
        </svg>
        <span class="sr-only">Search!</span>
      </button>
    </div>

    <div v-show="showResults"
         class="search__results search__results--large mt-3"
         ref="searchListInner">
      <div v-if="noValue"
           class="alert alert-light mb-0 border-0 rounded-0">
        ... type something to search for results
      </div>

      <div v-if="noResults && !loading"
           class="alert alert-light mb-0 border-0 rounded-0">
        <i class="fas fa-exclamation-triangle me-2"></i>Sorry, no results found
        within this search
      </div>

      <ul v-if="loading"
          class="search__results-list list-unstyled mb-0">
        <li v-for="index in 5"
            :key="index"
            class="search__result d-flex">
          <div class="search__result-logo me-2">
            <Skeletor width="50"
                      height="20" />
          </div>

          <div class="search__result-info">
            <div class="search__result-name fw-bold">
              <Skeletor width="25%" />
            </div>

            <div class="search__result-gbin">
              <Skeletor width="50%" />
            </div>
          </div>
        </li>
      </ul>

      <ul v-if="searchResults.length > 0 && !loading"
          class="search__results-list list-unstyled mb-0">
        <li v-for="(searchResult, resultIndex) in searchResults"
            :key="resultIndex"
            class="search__result d-flex">
          <div class="search__result-logo me-2">
            <img v-if="searchResult.logoUrl"
                 :src="searchResult.logoUrl"
                 :alt="searchResult.name"
                 class="img-fluid" />

            <img v-else
                 src="@/assets/images/placeholder-brand.png"
                 :alt="searchResult.name"
                 class="img-fluid" />
          </div>

          <div class="serach__result-info">
            <div class="search__result-name fw-bold">
              {{ searchResult.name }}
            </div>

            <router-link class="search__result-gbin stretched-link"
                         :to="getBrandUrl(searchResult.name, searchResult.gbin)"
                         tabindex="1">
              GBIN: <span>{{ searchResult.gbin }}</span>
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- /search -->
</template>

<script setup>
import { defineProps, onMounted, onUnmounted, ref, toRef, watch } from "vue";
import { useDebounceFn, useScroll } from "@vueuse/core";
import { api } from "@/utils/api";

const props = defineProps({
  totalCount: Number,
  isLarge: { type: Boolean, default: false },
  isCompact: { type: Boolean, default: false },
  placeholderText: { type: String },
});

// App variables
const showResults = ref(false);
const noValue = ref(true);
const loading = ref(false);
const noResults = ref(false);

// API results variables
const searchQuery = ref("");
const searchResults = ref([]);

/**
 * Handle click outside results
 */

function toggleShowResults(bool) {
  showResults.value = bool;
}

const root = ref(null);

// MOUNTED
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

// UNMOUNTED
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

function handleClickOutside(event) {
  if (!root.value.contains(event.target)) {
    showResults.value = false;
  }
}

/**
 * Data handling
 */

const callAPI = useDebounceFn(() => {
  searchResults.value = [];
  if (searchQuery.value !== "") {
    // Fetch the next batch of results, with a new query and with loader enabled
    offset = 0; // Reset the offset because we are about to start with a new query
    getSearchResult(searchQuery.value, true);
    noValue.value = false;
  } else {
    noValue.value = true;
    noResults.value = false;
    loading.value = false;
  }
}, 500);

const handleInput = (event) => {
  searchQuery.value = event.target.value;
  callAPI();
};

let limit = 20;
let offset = 0;

// Load API Search Result data
async function getSearchResult(
  searchQuery,
  newQuery = false,
  enableLoader = true
) {
  loading.value = enableLoader;

  try {
    const response = await api.getSearchResult(searchQuery, limit, offset);

    if (response.data.brands?.length) {
      searchResults.value = searchResults.value.concat(response.data.brands);
    } else if (!response.data.brands && newQuery) {
      searchResults.value = null;
    }

    offset = offset + 20;
  } catch (error) {
    console.error(error);
  } finally {
    if (searchResults.value === null) {
      searchResults.value = [];
      noResults.value = true;
    } else {
      noResults.value = false;
    }

    loading.value = false;
  }
}

/**
 * Lazy loading results with server side paging
 */
const searchListInner = ref();
const { arrivedState } = useScroll(searchListInner, {
  offset: { bottom: 300 },
});
const bottom = toRef(arrivedState, "bottom");
// Watch for when the user reaches bottom of the results div
watch(
  bottom,
  // Fetch the next batch of results, without a new query and without a loader
  (reachedBottom) =>
    reachedBottom && getSearchResult(searchQuery.value, false, false)
);

/**
 * Generate brand URL's for the vue-router links
 */
function getBrandUrl(name, gbin) {
  let brandName = name;

  brandName = brandName
    .normalize("NFD") // Remove accents/diacritics
    .replace(/\p{Diacritic}/gu, "") // Remove accents/diacritics
    .replace(/[^a-zA-Z0-9 \\d]/g, "") // Remove all special characters except space
    .replace(/^\s+|\s+$/g, "") // Remove whitespace at the beginning and end
    .replace(/\s+/g, "-") // Replace spaces between words with a hyphen (-)
    .toLowerCase(); // Convert into lowercase

  return `/brand/${brandName}/${gbin}`;
}
</script>

<style lang="scss"
       scoped>
      @import "~bootstrap/scss/functions";
      @import "~bootstrap/scss/variables";
      @import "~bootstrap/scss/mixins";

      @import "@/assets/scss/_variables";

      .search__wrapper {
        position: relative;
      }

      .search__bar {
        position: relative;

        .input-group-sm,
        .input-group-lg {
          .form-control {
            padding-right: 3rem;
          }
        }
      }

      .search__results {
        position: relative;
        left: 0;
        z-index: 100;

        width: 100%;
        max-height: 250px;
        overflow: auto;

        background-color: $white;
        border: 1px solid $gray-400;

        @include media-breakpoint-up(md) {
          position: absolute;
          max-height: calc(100vh - 400px);
        }
      }

      .search__result {
        position: relative;

        padding: 10px;
        border-bottom: 1px solid #e4e5e6;

        &:hover {
          background-color: $gbin-secondary-faded;
        }

        &:last-child {
          border-bottom: 0px;
        }
      }

      .search__result-info {
        flex: 1;
      }

      .search__result-logo {
        flex: 0 0 50px;
      }

      .search__result-gbin {
        color: #868d96;
      }

      .search__button {
        position: absolute;
        top: 50%;
        right: 1em;
        z-index: 4;

        background-color: transparent;
        border: 0px;

        color: #868d96;

        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      /* Scrollbar styling */
      .search__results::-webkit-scrollbar {
        width: 10px;
        background-color: #f3f3f3;
      }

      .search__results::-webkit-scrollbar-track {
        background: transparent;
        /* color of the tracking area */
      }

      .search__results::-webkit-scrollbar-thumb {
        background-color: #b0b8c0;
        /* color of the scroll thumb */
        border-radius: 0;
        /* roundness of the scroll thumb */
        border: 0px;
        /* creates padding around scroll thumb */
      }

      /* firefox scrollbar styling */
      .search__results {
        scrollbar-color: #b0b8c0 #f3f3f3;
        scrollbar-width: thin;
      }

      textarea:hover,
      input:hover,
      input:active,
      textarea:focus,
      textarea:active,
      input:focus,
      button:focus,
      button:active,
      button:hover,
      label:focus,
      .btn:active,
      .btn.active {
        outline: 0px !important;
        -webkit-appearance: none;
        box-shadow: none !important;
      }

      ::selection {
        background-color: $gbin-primary;
        color: white;
      }
    </style>
