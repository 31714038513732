import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  msalConfig,
  tokenRequest,
  msalInstance
} from "@/config/msalConfig";

/**
 * MSAL instance
 */
export const msal = new PublicClientApplication(msalConfig);

/**
 * Auth service
 */
export const Auth = {
  /**
   * Initialize and return active account
   */
  async initialize(client) {
    // start msal
    await msal.initialize();
    await msal.handleRedirectPromise();
    // hook into application router
    if (client) {
      msal.setNavigationClient(client);
    }
    // grab and set account if in session
    const accounts = msal.getAllAccounts();
    if (accounts?.length) {
      this.setAccount(accounts[0]);
    }

    // return any active account
    return msal.getActiveAccount();
  },

  /**
   * Login
   */
  async login() {
    const request = {
      redirectUri: msalConfig.auth.redirectUri,
      ...tokenRequest,
    };
    return msal
      .loginRedirect(request)
      .then((result) => {
        // set active account
        return this.setAccount(result.account);
      })
      .catch((error) => {
        // if we get stuck, clear session and attempt to log in again
        if (error.errorCode === "interaction_in_progress") {
          this.reset();
          return this.login();
        }
      });
  },

  /**
   * Logout
   */
  async logout() {
    const logoutRequest = { account: await msalInstance.getAllAccounts() };
    return msal
      .loginRedirect(logoutRequest)
      .then(() => {
        this.reset();
      })
      .catch((error) => {
        console.log(error);
      });
  },

  /**
   * Get token for api
   */
  async getToken() {
    return (
      msal
        // try getting the token silently
        .acquireTokenSilent(tokenRequest)

        // attempt login popup if this fails
        .catch(async (error) => {
          if (
            error instanceof InteractionRequiredAuthError
          ) {
            return msal.acquireTokenPopup(tokenRequest);
          }
          throw error;
        })
        .then((result) => {
          return result.accessToken;
        })
    );
  },

  setAccount(account) {
    msal.setActiveAccount(account);
    return account;
  },
  reset() {
    localStorage.clear();
  },
};
